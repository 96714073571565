<p-fieldset [legend]="reportsForm.label" [toggleable]="true">
  <div class="filter-container">
    <div class="filters">
      <app-fy-calender [style]="{ width: '16rem' }" label="Report Date Range" [isMandatory]="true" (onChange)="onChangeDates($event)" />
      <div class="flex align-items-center gap-2">
        <p-checkbox [(ngModel)]="dontShowCapitalGains" [binary]="true" inputId="dontShowCapitalGains"></p-checkbox>
        <label for="dontShowCapitalGains"
          >Don't show capital gains from trust distributions ("indirect gains") in realised and tax label reports</label
        >
      </div>
      <div class="flex align-items-center gap-2">
        <p-checkbox [(ngModel)]="dontShowTransferOrIntraFundGain" [binary]="true" inputId="dontShowTransferOrIntraFundGain"></p-checkbox>
        <label for="dontShowTransferOrIntraFundGain">Don't show transfer or intrafund gains in nett off and on nett off reports</label>
      </div>
      <div *ngIf="showSummaryOption" class="flex align-items-center gap-2">
        <p-checkbox [(ngModel)]="showSummary" [binary]="true" inputId="showSummary"></p-checkbox>
        <label for="showSummary">Show summary reports at underlying level</label>
      </div>
    </div>

    <p-button
      styleClass="p-button-outlined"
      label="Generate Report"
      (onClick)="onClickGenerateReport()"
      [loading]="reportsForm.isLoading"
      [disabled]="!canGenerateReport"
    ></p-button>
  </div>
</p-fieldset>
