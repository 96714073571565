import { Component, OnInit } from '@angular/core';
import { ReportsFormService } from '../../reports-form.service';
import { ICalenderEvent } from 'src/app/common/calender/calender.types';
import { RealisedParams, RealisedReport } from '../../reports.types';

@Component({
  selector: 'realised',
  templateUrl: './realised.component.html',
  styleUrls: ['./realised.component.css']
})
export class RealisedComponent implements OnInit {
  // Filters
  fromDate = '';
  toDate = '';

  showSummary = true;
  dontShowCapitalGains = true;
  dontShowTransferOrIntraFundGain = true;

  constructor(private reportsFormService: ReportsFormService) {}

  ngOnInit(): void {}

  get reportsForm() {
    return this.reportsFormService;
  }

  get showSummaryOption() {
    const subCat = this.reportsForm.subCategory as RealisedReport;
    return [RealisedReport.SUMMARY, RealisedReport.COST_RECON_SUMMARY].includes(subCat);
  }

  get canGenerateReport() {
    return !this.reportsForm.isLoading && this.reportsForm.portfolio?.id && this.fromDate && this.toDate;
  }

  onChangeDates = ({ fromDate, toDate }: ICalenderEvent) => {
    this.fromDate = fromDate;
    this.toDate = toDate;
  };

  onClickGenerateReport = () => {
    const portfolioId = this.reportsForm.portfolio?.id;
    const category = this.reportsForm.category;
    const subCategory = this.reportsForm.subCategory;
    const url = `api/reports/${category}/${subCategory}/${portfolioId}`;
    const body = {
      showSummary: this.showSummary,
      dontShowCapitalGains: this.dontShowCapitalGains,
      dontShowTransferOrIntraFundGain: this.dontShowTransferOrIntraFundGain,
      subPortfolioId: this.reportsForm.subPortfolioId,
      startDate: this.fromDate,
      endDate: this.toDate
    } as RealisedParams;
    this.reportsFormService.generateReport(url, body);
  };
}
