import { Component, OnInit } from '@angular/core';
import { ReportsFormService } from '../../reports-form.service';
import dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { PriceSelection, UnRealisedParams, UnrealisedReport } from '../../reports.types';
import { SERVER_DATE_FORMAT } from 'src/app/app.constants';

@Component({
  selector: 'unrealised',
  templateUrl: './unrealised.component.html',
  styleUrls: ['./unrealised.component.css']
})
export class UnrealisedComponent implements OnInit {
  // Filters
  showSummary = true;
  priceDate!: Date;
  // noOfDays: number = 0;
  priceSelectionType: PriceSelection = 0;

  priceSelectionOptions = [
    { label: 'eSTM Cloud Prices', key: PriceSelection.STM_PRICE },
    { label: 'User Prices', key: PriceSelection.USER_PRICE },
    { label: 'User Prices First, then eSTM Cloud Prices', key: PriceSelection.USER_PRICE_THEN_STM_PRICE }
  ];

  portfolioSubcriber!: Subscription;

  constructor(private reportsFormService: ReportsFormService) {
    this.portfolioSubcriber = this.reportsFormService.getPortfolioValue().subscribe((value) => {
      this.populatePriceDate();
    });
  }

  ngOnDestroy(): void {
    this.portfolioSubcriber.unsubscribe();
  }

  get reportsForm() {
    return this.reportsFormService;
  }

  get showSummaryOption() {
    const subCat = this.reportsForm.subCategory as UnrealisedReport;
    return [
      UnrealisedReport.SUMMARY,
      UnrealisedReport.PORTFOLIO_VALUATION,
      UnrealisedReport.PORTFOLIO_SUMMARY,
      UnrealisedReport.COST_RECON_SUMMARY
    ].includes(subCat);
  }

  get hidePriceDate() {
    return this.reportsForm.subCategory === UnrealisedReport.PORTFOLIO_SUMMARY;
  }

  get canGenerateReport() {
    return !this.reportsForm.isLoading && this.reportsForm.portfolio?.id && this.priceDate;
  }

  ngOnInit(): void {
    this.populatePriceDate();
  }

  private populatePriceDate = () => {
    const processedUptoDate = this.reportsForm.portfolio?.processUptoDate;
    this.priceDate = processedUptoDate ? dayjs(processedUptoDate).toDate() : new Date();
  };

  onClickGenerateReport = () => {
    const portfolioId = this.reportsForm.portfolio?.id;
    const category = this.reportsForm.category;
    const subCategory = this.reportsForm.subCategory;
    const isBaseReport = subCategory === UnrealisedReport.BASE_REPORT;
    const url = isBaseReport ? `api/base-reports/${category}/${portfolioId}` : `api/reports/${category}/${subCategory}/${portfolioId}`;
    const body = {
      showSummary: this.showSummary,
      priceDate: dayjs(this.priceDate).format(SERVER_DATE_FORMAT),
      // noOfDaysForBackwardSearch: this.noOfDays,
      priceSelectionType: this.priceSelectionType,
      subPortfolioId: this.reportsForm.subPortfolioId
    } as UnRealisedParams;
    this.reportsFormService.generateFinalReport(url, body, isBaseReport);
  };
}
