import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IPortfolio } from 'src/app/dashboard/portfolios/portfolios.component.types';
import { ISubPortfolio } from 'src/app/dashboard/sub-portfolios/sub-portfolios.component.types';
import { ReportsService } from '../reports.service';
import { ReportsFormService } from '../reports-form.service';
import { hideSubPortfolio } from 'src/app/app.constants';
import { Actions, Getters, State } from 'src/app/store';

@Component({
  selector: 'report-filters',
  templateUrl: './report-filters.component.html',
  styleUrls: ['./report-filters.component.css']
})
export class ReportFiltersComponent implements OnInit, OnDestroy {
  isLoadingPortfolio: boolean = false;
  isLoadingSubPortfolio: boolean = false;

  portfolios: IPortfolio[] = [];
  subPortfolios: ISubPortfolio[] = [];

  // Intentional - DONT REMOVE
  showSubPortfolioContent = !hideSubPortfolio;

  // portfolioSubcriber!: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private reportsFormService: ReportsFormService,
    private reportService: ReportsService,
    private storeService: Actions,
    private getStoreService: Getters
  ) {}

  ngOnInit(): void {
    this.fetchAllPortfolios();
    // this.portfolioSubcriber = this.reportsFormService.getPortfolioValue().subscribe((value) => {
    //   value?.id && this.fetchAllSubPortfolios(value.id);
    // });
  }

  ngOnDestroy(): void {
    // this.portfolioSubcriber?.unsubscribe();
  }

  get reportsForm() {
    return this.reportsFormService;
  }

  private fetchAllPortfolios = () => {
    this.isLoadingPortfolio = true;
    this.reportService.getAllPortfolios().subscribe({
      next: (res) => {
        this.portfolios = res as IPortfolio[];
        this.prePopulatePortfolio();
        this.isLoadingPortfolio = false;
      },
      error: (e) => {
        this.isLoadingPortfolio = false;
      }
    });
  };

  private fetchAllSubPortfolios = (portfolioId: number) => {
    this.isLoadingSubPortfolio = true;
    this.reportService.getAllSubPortfolios(portfolioId).subscribe({
      next: (res) => {
        const defaultAll = { id: Number.MIN_VALUE, subPortfolioName: 'ALL' } as ISubPortfolio;
        if (this.showSubPortfolioContent) {
          this.subPortfolios = [defaultAll, ...res] as ISubPortfolio[];
        } else {
          const hasOnlyOneSubPortfolio = res?.length === 1;
          this.subPortfolios = hasOnlyOneSubPortfolio ? ([...res] as ISubPortfolio[]) : ([defaultAll, ...res] as ISubPortfolio[]);
        }
        // this.subPortfolios = res as ISubPortfolio[];
        this.preSubPopulatePortfolio();
        this.isLoadingSubPortfolio = false;
      },
      error: (e) => {
        this.isLoadingSubPortfolio = false;
      }
    });
  };

  prePopulatePortfolio = () => {
    if (this.portfolios.length > 0) {
      let { portfolioId } = this.activatedRoute.snapshot.queryParams || {};

      // If found in query use query ids else use from store
      if (portfolioId) {
        this.storeService.setPortfolioId(portfolioId);
      } else {
        portfolioId = this.getStoreService.portfolioId();
      }

      const _portfolio = this.portfolios.find((item) => item.id === Number(portfolioId)) || this.portfolios[0];
      if (_portfolio) {
        this.reportsForm.portfolio = _portfolio;
        this.onPortfolioChange();
      }
    }
  };

  preSubPopulatePortfolio = () => {
    if (this.subPortfolios.length > 0) {
      let { subPortfolioId } = this.activatedRoute.snapshot.queryParams || {};

      // If found in query use query ids else use from store
      if (subPortfolioId) {
        this.storeService.setSubPortfolioId(Number(subPortfolioId));
      } else {
        subPortfolioId = this.getStoreService.subPortfolioId();
      }

      const _subPortfolio = this.subPortfolios.find((item) => item.id === Number(subPortfolioId)) || this.subPortfolios[0];
      if (_subPortfolio) {
        this.reportsForm.subPortfolio = _subPortfolio;
        this.onSubPortfolioChange(_subPortfolio);
      }
    }
  };

  onPortfolioChange = () => {
    this.fetchAllSubPortfolios(this.reportsForm.portfolio?.id);
    this.reportsForm.setPortfolioValue(this.reportsForm.portfolio);
    this.storeService.setPortfolioId(this.reportsForm.portfolio?.id);
    this.storeService.setClientId(this.reportsForm.portfolio?.clientId);
    this.reportsForm.updateQueryParams();
  };

  onSubPortfolioChange = (value: ISubPortfolio) => {
    if (value) {
      this.reportsForm.subPortfolio = value;
      this.storeService.setSubPortfolioId(value?.id);
      this.reportsForm.updateQueryParams();
    }
  };
}
